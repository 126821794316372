@font-face {
  font-family: 'Helvetica Neue';
  src: url('/fonts/helveticaneue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon.eot?oq9ykp');
  src:  url('/fonts/icomoon.eot?oq9ykp#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?oq9ykp') format('truetype'),
        url('/fonts/icomoon.woff?oq9ykp') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}